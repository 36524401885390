<template>
  <div id="report-seguimiento">
    <v-container grid-list-xl fluid>
      <header-title title="Seguimiento Tiempo Real"></header-title>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
          ></v-text-field>
        </v-col>
        <!-- <v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showStatistic">
            <v-icon>mdi-image-outline</v-icon>Gráfica
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="atendidos"
            :loading="loading"
            :search="search"
            no-data-text="No existen registros"
            no-results-text="Sin resultados"
            item-key="name"
            class="elevation-1"
            v-cloak
          >
            <template v-slot:item.tipo_tramite="{ item }">
              <td>
                {{
                  item.estado_id == 1 || item.estado_id == 2
                    ? ""
                    : item.tipotramite
                }}
              </td>
            </template>
            <template v-slot:item.tiempo="{ item }">
              <td>
                {{
                  item.tiempo.hours == undefined ? "0" : item.tiempo.hours
                }}:{{
                  item.tiempo.minutes == undefined ? "0" : item.tiempo.minutes
                }}:{{
                  item.tiempo.seconds == undefined ? "0" : item.tiempo.seconds
                }}
              </td>
            </template>
            <template v-slot:item.estado="{ item }">
              <td>
                <span v-if="item.estado_id == 1">
                  <v-chip small color="green lighten-4">atención</v-chip>
                </span>
                <span v-else-if="item.estado_id == 3">
                  <v-chip small color="blue lighten-4">finalizado</v-chip>
                </span>
                <span v-else>
                  <v-chip small color="orange lighten-4">en proceso</v-chip>
                </span>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "seguimiento-tiempo-real",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Operador ", value: "nombre_operador" },
        { text: "Tipo trámite ", value: "tipo_tramite", sortable: false },
        { text: "Hora inicio ", value: "hora_inicio" },
        { text: "Hora fin ", value: "hora_fin" },
        { text: "Tiempo atención ", value: "tiempo", sortable: false },
        { text: "Estado ", value: "estado" },
        { text: "Plataforma ", value: "plataforma" },
      ],
      role: 4,
      fecha: new Date().toISOString().substr(0, 10),
      snack: {
        state: false,
        color: "success",
        mode: "",
        timeout: 2500,
        text: "",
      },
    };
  },
  mounted() {
    if (Service.getUser()) {
      this.role = Service.getUser().role;
      if (this.role != 1) {
        this.getSeguimiento();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  computed: {
    atendidos() {
      return this.$store.state.results;
    },
  },
  methods: {
    getSeguimiento() {
      //this.$socket.emit("listSeguimiento", this.fecha);
    },
    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
